import React, { useCallback, useState } from "react";

const StringBinding = require("sharedb-string-binding");

import { Connection } from "sharedb/lib/client";

function generateGuid() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

export function useConnector(setCurrentDoc?: (arg0: null) => void) {
  const [guid, setGuid] = useState(generateGuid());
  const [translatedData, setTranslatedData] = useState<any[]>([]);
  const [operationCounter, incrementOperationCounter] = useState<number>(0);
  const [connect, setConnected] = useState(false);
  const [doc, setDoc] = useState(null);
  const [currentRoomId, setRoomId] = useState(null);
  const [bindingReady, setBindingReady] = useState(false);
  let _doc: any /** React.SetStateAction<null> */ = null;
  let killed = false;

  const _maxReconnectRetry = 10;
  const _reconnectDelay = 3000;
  const accessToken = "token-123";
 
  const shareDbWsUrls: any = {
    main: "wss://main-controller.dev.liveaccess.net/",
    preprocessed: "wss://preprocessing-passive.dev.liveaccess.net/",
    fr: "wss://translate-controller-fr-passive.dev.liveaccess.net/",
    en: "wss://translate-controller-en-passive.dev.liveaccess.net/",
    it: "wss://translate-controller-it-passive.dev.liveaccess.net/",
    de: "wss://translate-controller-de-passive.dev.liveaccess.net/",
    uk: "wss://translate-controller-uk-passive.dev.liveaccess.net/",
    rm: "wss://translate-controller-rm-passive.dev.liveaccess.net/",
  };

 /*
  const shareDbWsUrls: any = {
    main: "wss://prod-main-controller.madarray.solutions/",
    preprocessed: "wss://prod-preprocessing-passive.madarray.solutions/",
    fr: "wss://prod-translate-controller-fr.madarray.solutions/",
    en: "wss://prod-translate-controller-en-passive.madarray.solutions/",
    it: "wss://prod-translate-controller-it-passive.madarray.solutions/",
    de: "wss://prod-translate-controller-de-passive.madarray.solutions/",
    uk: "wss://prod-translate-controller-uk-passive.madarray.solutions/",
    rm: "wss://prod-translate-controller-rm-passive.madarray.solutions/",
  };
*/
  const translationCollections: any = {
    main: "main",
    preprocessed: "preprocessed",
    fr: "fr-translations",
    en: "en-translations",
    it: "it-translations",
    de: "de-translations",
    uk: "uk-translations",
    rm: "rm-translations",
  };

  let _ws: any /*WebSocket | null*/ = null;
  let _binding: { destroy: () => void; setup: () => void } | null = null;

  const closeDoc = () => {
    if (!_ws) return;
    if (_ws.readyState === 2 || _ws.readyState === 3) {
      return;
    }
    if (_binding) {
      _binding.destroy();
    }
    _binding = null;

    try {
      if (_doc && _doc.connection) {
        _doc?.connection?.close();
      }
    } catch (_) {
      console.log(_);
    }
    try {
      if (_doc && _ws.readyState !== 2 && _ws.readyState !== 3) {
        _doc.unsubscribe();
      }
    } catch (_) {
      console.log(_);
    }
    _doc = null;
    if (setCurrentDoc) {
      setCurrentDoc(null);
    }
  };

  const close = () => {
    killed = true;
    closeDoc();
    setBindingReady(false);
  };

  const _handleDoc = (collection: string, nativeElement: any) => {
    if (killed) {
      close();
      return;
    }
    if (collection === "main" && _binding === null) {
      _binding = new StringBinding(nativeElement, _doc, ["content"]);
      _binding?.setup();
      setBindingReady(true);
    }
    if (collection !== "main" && _doc.data && _doc.data) {
      const translations = _doc.data.content ?? [];
      setTranslatedData(translations);
      setConnected(true);
    }
    setGuid(generateGuid());
  };

  const onConnect = async (
    connection: { get: (arg0: any, arg1: any) => any },
    roomId: any,
    collection: any,
    nativeElement: any,
    cb: any
  ) => {
    if (killed) {
      close();
      return;
    }
    let counter = 0;
    if (!!_doc) {
      closeDoc();
    }
    _doc = connection.get(translationCollections[collection], roomId);
    setDoc(_doc);
    if (setCurrentDoc) {
      setCurrentDoc(_doc);
    }

    _doc.subscribe(() =>
      _handleDoc(translationCollections[collection], nativeElement)
    );
    _doc.on("op", (op: any) => {
      counter = counter + 1;
      cb(counter);
      _handleDoc(translationCollections[collection], nativeElement);
    });
  };

  const heartbeat = () => {};

  const openSocket = (
    socketUrl: string | URL,
    accessToken: string,
    roomId: any,
    collection: any,
    nativeElement: any,
    onConnect: {
      (
        connection: any,
        roomId: any,
        collection: any,
        nativeElement: any,
        cb: any
      ): Promise<void>;
      (arg0: any, arg1: any, arg2: any, arg3: any, arg4: any): void;
    },
    cb: any
  ) => {
    let retryCount = 0;
    _ws = new WebSocket(socketUrl);
    _ws.addEventListener("open", () => {
      heartbeat();
      _ws?.send(JSON.stringify({ accessToken, roomId }));
    });
    _ws.addEventListener("ping", heartbeat);

    _ws.addEventListener("message", async (message: any) => {
      if (message.data === "authenticated") {
        retryCount = 0;
        onConnect(new Connection(_ws), roomId, collection, nativeElement, cb);
      }
    });

    _ws.addEventListener("close", (ev: any) => {
      closeDoc(/**ev */);

      if ([3000, 1002].indexOf(ev.code) === -1) {
        if (retryCount < _maxReconnectRetry) {
          retryCount++;
          setTimeout(() => {
            if (killed) {
              return;
            }
            openSocket(
              socketUrl,
              accessToken,
              roomId,
              collection,
              nativeElement,
              onConnect,
              cb
            );
          }, _reconnectDelay);
        } else {
          close();
        }
      }
    });

    return _ws;
  };

  const init = (
    id: any,
    collection: React.SetStateAction<null>,
    serverRef: string | number,
    nativeElement: any
  ) => {
    setBindingReady(false);
    const roomId = id;
    setRoomId(collection);
    const socketUrl = shareDbWsUrls[serverRef];
    var cb = incrementOperationCounter;
    openSocket(
      socketUrl,
      accessToken,
      roomId,
      collection,
      nativeElement,
      onConnect,
      cb
    );
  };

  const handlers = {
    init: useCallback(init, []),
    close: useCallback(close, []),
  };
  const currentDoc = _doc;
  return [
    bindingReady,
    guid,
    operationCounter,
    translatedData,
    connect,
    handlers,
    currentDoc,
  ];
}
/* eslint-enable */
