import React, { useEffect, useState, useRef, useCallback } from "react";
import { createStyles, Text, Paper } from "@mantine/core";
import { Scrollbar } from "react-scrollbars-custom";

import { useSearchParams } from "react-router-dom";
import {
  IReplayClient,
  ReplayClient,
  ReplayLine,
} from "../../../hooks/replayconnector.hook";

const TranslationArea = ({
  event,
  autoScroll,
  textCenter,
  fontSize,
  language,
  variation,
  downloadPdf,
  delay,
  color,
}: any) => {
  const refContainer: any = useRef();
  const [connected, setConnected] = useState(false);
  const [lines, setLines]: any[] = useState([]);

  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);
  let componentRef: any = useRef();

  const scrollbarRef: any = useRef<HTMLDivElement>();

  const setLinesFunction = useCallback(
    (line: any) => {
      setLines((lines: any) => [...lines, ...line]);
    },
    [lines]
  );
  const _delay = delay != undefined ? delay * 1000 : 0; //ms

  
  useEffect(() => {
    const startTime = Date.now() - _delay;
    let _replayClient: IReplayClient;

    _replayClient = new ReplayClient();
    _replayClient.connect(
      // "wss://replay-controller.dev.liveaccess.net",
      "wss://prod-replay-controller.madarray.solutions",
      "token-123",
      event.id,
      startTime,
      language,
      (line: any) => setLinesFunction(line),
      (ev: any) => console.log("zxcev", ev),
      () => console.log("zxconnection lost....")
    );
    return () => {
      _replayClient.disconnect();
    };
  }, []);

  useEffect(() => {
    if (autoScroll && scrollbarRef.current) {
      scrollToBottom();
    }
  }, [scrollbarRef, autoScroll, fontSize, language]);

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
    if (autoScroll) {
      setTimeout(() => {
        scrollToBottom();
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (autoScroll && scrollbarRef.current) {
      scrollToBottom();
    }
  }, [scrollbarRef, language, urlParams]);

  const renderTranslations = (trans: any) => {
    return trans.map((sentenceData: any, index: number) => {
      return (
        <div
          key={index}
          style={
            color.backgroundColor !== "transparent"
              ? { textAlign: "left" }
              : { textAlign: "center" }
          }
        >
          <Text
            lang={language}
            style={{
              fontSize: `${fontSize}px `,
              wordBreak: "break-word",
              textAlign: textCenter && "center",
              lineHeight:
                variation == 2
                  ? fontSize == 39
                    ? "1.6"
                    : fontSize == 16
                    ? "1.55"
                    : fontSize == 26
                    ? "1.55"
                    : "1.5"
                  : "1.5",
            }}
          >
            {sentenceData.value}
          </Text>
        </div>
      );
    });
  };

  const scrollToBottom = () => {
    scrollbarRef.current.scrollToBottom({ behavior: "smooth" });
  };

  const textareaStyle = {
    ...color,
    outline: "none",
    fontSize: `${fontSize}px `,
    width: "100%",
    height: "100%",
    padding: variation == 2 ? "0px 70px" : variation == 3 ? "16px 70px" : 16,
    resize: "none",
    lineHeight:
      variation == 2
        ? fontSize == 39
          ? "1.6"
          : fontSize == 16
          ? "1.55"
          : fontSize == 26
          ? "1.55"
          : "1.5"
        : "1.5",
  };

  return (
    <Paper
      ref={(el: any) => (componentRef = el)}
      radius={0}
      style={textareaStyle}
    >
      <Scrollbar
        noScrollY={true}
        style={{ width: "100%", height: "100%" }}
        ref={scrollbarRef}
        tabIndex={0}
      >
        {lines && lines.length > 0 && renderTranslations(lines)}
      </Scrollbar>
    </Paper>
  );
};

export default TranslationArea;
