import { useState, useEffect, useCallback, useRef } from "react";
import {
  createStyles,
  ActionIcon,
  Group,
  Menu,
  Image,
  Loader,
} from "@mantine/core";
import { useDisclosure, useLocalStorage, useHover } from "@mantine/hooks";
import {
  Settings,
  ArrowsDiagonal,
  Language,
  Palette,
} from "tabler-icons-react";
import Loading from "../loading";
import { TbLayoutColumns, TbVideo, TbVideoOff } from "react-icons/tb";
import ColorSettings from "./colorSettings";
import Player from "../player";
import { IEvent } from "../../../interfaces/event.interface";
import TextAreaEditor from "./textAreaEditor";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { logEventAnalytics } from "../../../api/firebase";
import RichTextEditor from "./richText";
import InterpreterFullScreenSettings from "../interpreter-settings/interpreterFullScreenSettings";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadPdf from "../../low-level/download-pdf/downloadPdf";
import { BsFiletypeDocx, BsFiletypePdf } from "react-icons/bs";
import useVideo from "../../../hooks/show-video.hooks";
import SignLanguagePublic from "../public-event/SignLanguagePublic";
import useSignLanguage from "../../../hooks/sign-language.hooks";
import TranslationReplayArea from "./translationReplayArea";
import TranslationArea from "./translationArea";
import DownloadTxtButton from "./settingsArea/downloadTxtButton";
import { isLoadedInIframe } from "../../../utils/is-loaded-in-Iframe";
import {
  IReplayClient,
  ReplayClient,
  ReplayLine,
} from "../../../hooks/replayconnector.hook";
// Statisches Array von Untertiteln
const subtitles = [
  {
    startTime: 0,
    endTime: 5,
    text: "Doch der Fuchs wusste nicht, dass der\nKristall nur von jenen berührt werden",
  },
  // Weitere Untertitelobjekte können hier hinzugefügt werden
];

const InterpreterArea = (props: { event: IEvent }) => {
  const { event } = props;
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);
  const { ref } = useHover();
  const { t, i18n } = useTranslation("common");

  const [mobile, setMobile] = useState(false);
  const { firebasePublicGetData, signLanguageState } = useSignLanguage(
    event?.id
  );
  const [textFullSize, setTextFullSize] = useLocalStorage({
    key: "textFullSize",
    defaultValue: false,
  });
  const [divideIntoTwo, setDivideIntoTwo] = useState(false);
  const [textAreaColor, setTextAreaColor] = useState("dark");
  const [textAreaFontSize, setTextAreaFontSize] = useLocalStorage({
    key: "textAreaFontSize",
    defaultValue: 24,
  });
  const [textFontV2, setTextFontV2]: any = useState(26);
  const [variation, setVariation]: any = useState(1);
  const [variationNoVideo, setVariationNoVideo]: any = useState(0);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [whiteColor, setWhiteColor] = useState(false);
  const [showSignLanguage, setShowSignLanugage] = useState(true);
  const [settingsOpenFullScreen, setSettingsOpenFullScreen] = useState(false);
  const [colorMenu, setColorMenu] = useDisclosure(false);
  const [openLanguage, handlersLanguage] = useDisclosure(false);
  const [scale, setScale] = useState(false);
  const [pdfButtonDisabled, setPdfButtonDisabled] = useState(true);
  const [languagePosition, setSignLanguagePosition] = useState(1);
  const [downloadWorld, setDownloadDocx] = useState(false);
  const [showDownloadDocx, setShowDownloadDocx] = useState(false);
  const [inIframe, setIsLoadedInIframe] = useState(false);
  const { classes } = useStyles();
  const playerOptions = {};
  const [player, setPlayer] = useState(null);
  const [currentTranslation, setCurrentTranslation] = useState<null | string>(
    null
  );
  const [currentSecondTranslation, setCurrentSecondTranslation] = useState<
    null | string
  >(null);

  const [showTranslation, setShowTranslation] = useState(false);
  const [showSecondTranslation, setShowSecondTranslation] = useState(false);
  const [showVideo, setShowVideo]: any = useState(false);
  const [currentDocId, setCurrentDocId] = useState<null | string>(null);
  const { video, currentRaiseHandState, initLoaded } = useVideo(currentDocId);

  const colorData = [
    { color: "light" },
    { color: "light_text_background" },
    { color: "green" },
    { color: "green_black" },
    { color: "blue" },
    { color: "blue_black" },
    { color: "transparent_text" },
    { color: "transparent" },
  ];

  const ShowDownloadTxtButton: React.FC = useCallback(() => {
    return (
      <DownloadTxtButton
        event={event}
        language={currentTranslation}
        t={t}
        whiteColor={whiteColor}
      />
    );
  }, [currentTranslation]);
  const handleSetSecondTranslation = (translation: string) => {
    setShowSecondTranslation(true);
    setCurrentSecondTranslation(translation);
  };

  const handleShowSecondSource = () => {
    setShowSecondTranslation(false);
    setCurrentSecondTranslation(null);
  };
  const handleSetTranslation = (translation: string) => {
    setShowTranslation(true);
    setCurrentTranslation(translation);
    try {
      logEventAnalytics("changeTranslation", { translation: translation });
      logEventAnalytics(event.shortUid + " -> " + translation, {
        translation: translation,
      });
    } catch (error) {}
  };

  const handleShowSource = () => {
    setShowTranslation(false);
    setCurrentTranslation(null);
  };
  const handleShowSourceEdit = () => {
    setShowTranslation(true);
    setCurrentTranslation("pre");
    setCurrentDocId(event.id + "-preprocessed");
  };
  const handleShowSecondSourceEdit = () => {
    setShowSecondTranslation(true);
    setCurrentSecondTranslation("preprocessed");
  };
  useEffect(() => {
    if (!event || !event.shortUid) return;
    logEventAnalytics(event.shortUid, { event: event?.shortUid });
  }, [event]);

  useEffect(() => {
    if (event && event?.showPlayer) {
      setCurrentDocId(event.id);
    }
  }, [event]);

  useEffect(() => {
    if (event && showVideo && showSignLanguage) {
      firebasePublicGetData(event.id);
    }
  }, [event, showVideo, showSignLanguage]);

  const signLanguagePosition = () => {
    if (languagePosition == 3) {
      setSignLanguagePosition(1);
    } else {
      setSignLanguagePosition(languagePosition + 1);
    }
  };

  useEffect(() => {
    if (urlParams?.split != undefined) {
      if (JSON.parse(urlParams?.split?.toLowerCase())) {
        setDivideIntoTwo(true);
        if (
          urlParams?.language === "main" ||
          urlParams?.language == undefined
        ) {
          handleShowSource();
        } else {
          handleSetSecondTranslation(urlParams?.language);
          setCurrentTranslation(urlParams?.language);
          setShowTranslation(true);
        }
        if (
          urlParams?.secondlanguage === "main" ||
          urlParams?.secondlanguage == undefined
        ) {
          handleShowSecondSource();
        } else {
          handleSetSecondTranslation(urlParams?.secondlanguage);
          setCurrentSecondTranslation(urlParams?.secondlanguage);
          setShowSecondTranslation(true);
        }
      } else {
        setDivideIntoTwo(false);
        if (urlParams?.language === "main") {
          handleShowSource();
        } else {
          handleSetSecondTranslation(urlParams?.language);
          setCurrentTranslation(urlParams?.language);
          setShowTranslation(true);
        }
      }
    } else {
      if (urlParams?.language != undefined) {
        if (urlParams?.language === "main") {
          handleShowSource();
        } else {
          handleSetTranslation(urlParams?.language);
          setCurrentTranslation(urlParams?.language);
          setShowTranslation(true);
        }
      }
      if (urlParams?.secondlanguage != undefined) {
        if (urlParams?.secondlanguage === "main") {
          handleShowSecondSource();
        } else {
          handleSetSecondTranslation(urlParams?.secondlanguage);
          setCurrentSecondTranslation(urlParams?.secondlanguage);
          setShowSecondTranslation(true);
        }
      }
    }
  }, [urlParams, searchParams]);

  useEffect(() => {
    if (!showVideo) {
      setVariation(1);
    }
  }, [showVideo]);

  useEffect(() => {
    if (window?.innerWidth < 769) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (urlParams?.hiddenVideo == undefined) {
      setShowVideo(props?.event?.showPlayer);
    } else {
      setShowVideo(!JSON.parse(urlParams?.hiddenVideo?.toLowerCase()));
    }
  }, [searchParams, props]);

  useEffect(() => {
    const loadedInIframe = isLoadedInIframe();
    setIsLoadedInIframe(loadedInIframe);
    if (loadedInIframe) {
      console.log("GO TO FULLSIZE");
      // changeScaleTo(3);
    }
  }, []);

  useEffect(() => {
    if (urlParams?.hiddenVideo != undefined) {
      if (urlParams?.view != undefined) {
        if (!JSON.parse(urlParams?.hiddenVideo?.toLowerCase())) {
          setVariation(JSON.parse(urlParams?.view));
        } else {
          setVariationNoVideo(JSON.parse(urlParams?.view));
        }
      } else {
        if (JSON.parse(urlParams?.hiddenVideo?.toLowerCase())) {
          setVariationNoVideo(1);
        } else {
          setVariationNoVideo(0);
          setVariation(1);
        }
      }
    } else {
      if (urlParams?.view != undefined) {
        if (event?.showPlayer) {
          setVariation(JSON.parse(urlParams?.view));
          setVariationNoVideo(0);
        } else {
          setVariationNoVideo(JSON.parse(urlParams?.view));
        }
      } else {
        if (!scale) {
          if (event?.showPlayer) {
            setVariation(1);
            setVariationNoVideo(0);
          } else {
            setVariationNoVideo(1);
          }
        }
      }
    }
  }, [urlParams, searchParams, event]);

  useEffect(() => {
    if (urlParams.fontSize != undefined) {
      setTextAreaFontSize(JSON.parse(urlParams.fontSize));
    }
  }, [urlParams, searchParams]);

  useEffect(() => {
    if (variation != 2) {
      colorData.map((x: any, i: any) => {
        if (urlParams?.color?.includes(i + 1)) {
          setTextAreaColor(x.color);
        }
      });
    } else {
      setTextAreaColor("transparent_text");
    }
  }, [urlParams, variation, searchParams]);

  useEffect(() => {
    var white = false;
    if (textFullSize) white = true;
    else if (variation == 2) white = true;
    else if (variation == 3) white = true;
    else white = false;
    setWhiteColor(white);
  }, [textFullSize, settingsOpen, variation]);

  useEffect(() => {
    if (variation == 1 && textAreaColor == "transparent_text") {
      setTextAreaColor("dark");
    }
  }, [variation, textAreaColor]);

  useEffect(() => {
    if (variation == 2) {
      getTextAreaColor("transparent_text");
    }
    if (variation == 3) {
      getTextAreaColor("dark");
    }
  }, [variation]);

  const getTextAreaColor = (color: string) => {
    setTextAreaColor(color);
  };

  const changeScale = () => {
    setScale(true);
    if (variation == 3) setVariation(1);
    else {
      setVariation(variation + 1);
    }
  };

  const changeScaleTo = (scaleNumber: number) => {
    setScale(true);
    setVariation(scaleNumber);
  };

  useEffect(() => {
    if (urlParams.view == undefined) {
      if (!scale && !props?.event?.showPlayer) {
        setVariationNoVideo(1);
      }
    }
  }, [props?.event?.showPlayer, scale, urlParams]);

  const changeScaleNoVideo = () => {
    setScale(true);
    if (variationNoVideo == 3) setVariationNoVideo(1);
    else {
      setVariationNoVideo(variationNoVideo + 1);
    }
  };

  const fullSize = () => {
    setTextFullSize(!textFullSize);
    setSettingsOpen(true);
    if (textAreaColor == "transparent") {
      getTextAreaColor("dark");
    }
  };

  useEffect(() => {
    if (variationNoVideo == 3) {
      fullSize();
    } else {
      setTextFullSize(false);
      setSettingsOpen(false);
    }
  }, [variationNoVideo]);

  const settingsMenuClose = () => {
    setVariation(variation);
    setTextFullSize(false);
    settingsOpenFullScreen;
    setSettingsOpen(false);
    if (textAreaColor == "transparent") {
      getTextAreaColor("light");
    }
  };
  if (!event) return <Loading />;

  const handleAudioTracks = (tracks: any) => {
    // setAudioTracks(tracks);
    // setCurrentTrack(tracks[0]);
  };

  const color = () => {
    var currentColor: any = "";
    if (textAreaColor == "light")
      currentColor = {
        backgroundColor: "white",
        // overflowY: "hidden",
        color: "black",
        border: "0px solid black",
      };

    if (textAreaColor == "light_text_background")
      currentColor = {
        backgroundColor: "black",
        color: "white",
      };
    if (textAreaColor == "dark")
      currentColor = {
        backgroundColor: "black",
        color: "white",
      };
    if (textAreaColor == "green")
      currentColor = {
        backgroundColor: "rgb(0, 255, 0)",
        color: "white",
        border: "0px solid black",
      };
    if (textAreaColor == "green_black")
      currentColor = {
        backgroundColor: "rgb(0, 255, 0)",
        color: "black",
        border: "0px solid black",
      };
    if (textAreaColor == "blue")
      currentColor = {
        backgroundColor: "rgb(0, 51, 204)",
        color: "white",
        border: "0px solid black",
      };
    if (textAreaColor == "blue_black")
      currentColor = {
        backgroundColor: "rgb(0, 51, 204)",
        color: "black",
        border: "0px solid black",
      };
    if (textAreaColor == "transparent_text")
      currentColor = {
        backgroundColor: "rgb(0,0,0,.5)",
        color: "white",
        border: "0px solid black",
        overflowY: "hidden",
        textShadow: "2px 2px #000000",
      };
    if (textAreaColor == "transparent")
      currentColor = {
        backgroundColor: "transparent",
        color: "white",
        border: "0px solid black",
        overflowY: "hidden",
        textShadow: "2px 2px #000000",
      };
    return currentColor;
  };

  const textFontSizeIncrease = () => {
    if (variation == 2) {
      if (textFontV2 == 16) {
        setTextFontV2(21);
      }
      if (textFontV2 == 21) {
        setTextFontV2(26);
      }
      if (textFontV2 == 26) {
        setTextFontV2(39);
      }
      if (textFontV2 == 39) {
        setTextFontV2(91);
      }
    } else {
      if (textAreaFontSize < 100) setTextAreaFontSize(textAreaFontSize + 2);
    }
  };

  const textFontSizeDecrease = () => {
    if (variation == 2) {
      if (textFontV2 == 91) {
        setTextFontV2(39);
      }
      if (textFontV2 == 39) {
        setTextFontV2(26);
      }
      if (textFontV2 == 26) {
        setTextFontV2(21);
      }
      if (textFontV2 == 21) {
        setTextFontV2(16);
      }
    } else {
      if (textAreaFontSize > 10) setTextAreaFontSize(textAreaFontSize - 2);
    }
  };

  const getDivideIntoTwo = () => {
    setDivideIntoTwo(!divideIntoTwo);
    try {
      logEventAnalytics("useSplitScreen", {
        setTo: !divideIntoTwo ? "true" : "false",
      });
    } catch (error) {}
  };

  // ##############################

  const languageList = () => {
    return (
      <div className={classes.language_box}>
        <div className={classes.language_box_column}>
          <Menu.Label>{divideIntoTwo ? "Left View" : "Language"}</Menu.Label>
          {event.translations.map((x: any, i: number) => (
            <Menu.Item
              key={i}
              aria-label={t(`${x}-language`)}
              lang="en"
              onClick={() => handleSetTranslation(x)}
            >
              {x.toUpperCase()}
            </Menu.Item>
          ))}
          <Menu.Item
            aria-label={t("source")}
            lang="en"
            onClick={() => handleShowSource()}
          >
            {t("source")}
          </Menu.Item>
          <Menu.Item
            aria-label={t("source_edit")}
            lang="en-US"
            onClick={() => handleShowSourceEdit()}
          >
            {t("source_edit")}
          </Menu.Item>
        </div>
        <div className={`${divideIntoTwo && classes.language_box_column}`}>
          {divideIntoTwo && (
            <>
              <Menu.Label>Right View</Menu.Label>
              {event.translations.map((x: any, i: number) => (
                <Menu.Item
                  key={i}
                  aria-label={t(`${x}-language`)}
                  lang="en"
                  onClick={() => handleSetSecondTranslation(x)}
                >
                  {x.toUpperCase()}
                </Menu.Item>
              ))}
              <Menu.Item
                aria-label={t("source")}
                lang="en"
                onClick={() => handleShowSecondSource()}
              >
                {t("source")}
              </Menu.Item>
              <Menu.Item
                aria-label={t("source_edit")}
                lang="en"
                onClick={() => handleShowSecondSourceEdit()}
              >
                {t("source_edit")}
              </Menu.Item>
            </>
          )}
        </div>
      </div>
    );
  };

  const settingsItems = () => {
    return (
      <Group
        ml="xs"
        pb={variation == 1 ? (textFullSize ? 0 : 5) : 0}
        pt={variation == 1 ? (textFullSize ? 0 : 5) : 0}
        spacing={variation == 1 && !textFullSize ? (mobile ? 10 : 5) : 10}
        mr="xs"
        style={{
          width: mobile ? "fit-content" : undefined,
          height: "fit-content",
          margin:
            variation != 1
              ? "0px 0px"
              : textFullSize
              ? "0px 0px"
              : mobile
              ? "0px 0px "
              : "0px 10px",
        }}
        className={
          variation == 1 && !textFullSize
            ? classes.settings_button_area
            : undefined
        }
        align="center"
        position={mobile ? "right" : undefined}
        direction={
          textFullSize ? "column" : variation == 1 && mobile ? "row" : "column"
        }
      >
        {!textFullSize &&
          !mobile &&
          variation != 2 &&
          variation != 3 &&
          showVideo && (
            <ActionIcon
              aria-label={t("publicPage.change-size")}
              onClick={changeScale}
              lang="en"
              size="lg"
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    color: whiteColor ? "white" : "black",
                  }}
                  className={classes.variation}
                >
                  {variation}
                </h3>
                <ArrowsDiagonal
                  style={{ width: "100%" }}
                  size="md"
                  color={textFullSize ? "white" : "black"}
                />
              </div>
            </ActionIcon>
          )}
        {!textFullSize && !mobile && !showVideo && (
          <ActionIcon
            aria-label={t("publicPage.change-size")}
            onClick={changeScaleNoVideo}
            lang="en"
            size="lg"
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  color: whiteColor ? "white" : "black",
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                }}
              >
                {variationNoVideo}
              </h3>
              <ArrowsDiagonal
                style={{ width: "100%" }}
                size="md"
                color={textFullSize ? "white" : "black"}
              />
            </div>
          </ActionIcon>
        )}

        <>
          {!textFullSize &&
            variation != 2 &&
            variation != 3 &&
            !mobile && ( // full screen and non mobile settings area
              <ActionIcon
                size="lg"
                lang="en-US"
                aria-label={t("settings")}
                variant={settingsOpen ? "filled" : undefined}
                color={settingsOpen ? "red" : "white"}
                onClick={() => {
                  setSettingsOpen(!settingsOpen);
                  setSettingsOpenFullScreen(false);
                }}
              >
                <Settings
                  style={{ width: "100%" }}
                  size="xl"
                  color={settingsOpen ? "white" : "black"}
                />
              </ActionIcon>
            )}
          {(settingsOpen || settingsOpenFullScreen) && (
            <>
              {/* {!textFullSize && !props?.event?.showPlayer && (
                <ActionIcon
                  aria-label={t("full-size-text")}
                  size="md"
                  onClick={fullSize}
                >
                  <ArrowsMaximize
                    style={{ width: "100%" }}
                    className={`${!mobile && classes.icon_hover}`}
                    size="xl"
                    color="red"
                  />
                </ActionIcon>
              )} */}

              <Menu
                opened={openLanguage}
                control={
                  <ActionIcon
                    lang="en-US"
                    aria-label={t("publicPage.select-language")}
                    size="lg"
                  >
                    <Language
                      style={{ width: "100%" }}
                      className={`${!mobile && classes.icon_hover}`}
                      aria-label={t("publicPage.select-language")}
                      size="xl"
                      color={whiteColor ? "white" : "black"}
                    />
                  </ActionIcon>
                }
                styles={{
                  body: { width: "auto" },
                  itemBody: { justifyContent: "center" },
                  label: { justifyContent: "center", display: "flex" },
                }}
                onOpen={handlersLanguage.open}
                onClose={handlersLanguage.close}
              >
                {languageList()}
              </Menu>
              {variation != 2 && !event.isRichText && (
                <Menu
                  opened={colorMenu}
                  lang="en-US"
                  size="xs"
                  control={
                    <ActionIcon
                      aria-label={t("publicPage.select-color")}
                      size="lg"
                      style={{ width: 32 }}
                    >
                      <Palette
                        style={{ width: "100%" }}
                        aria-label={t("publicPage.select-color")}
                        className={`${!mobile && classes.icon_hover}`}
                        size="xl"
                        color={whiteColor ? "white" : "black"}
                      />
                    </ActionIcon>
                  }
                  onOpen={setColorMenu.open}
                  onClose={setColorMenu.close}
                >
                  <ColorSettings
                    variation={variation}
                    getTextAreaColor={getTextAreaColor}
                  />
                </Menu>
              )}

              <ActionIcon
                style={{ overflow: "hidden" }}
                aria-label={t("publicPage.font_size_increase")}
                onClick={textFontSizeIncrease}
                lang="en"
                size="lg"
              >
                <div
                  className={`${!mobile && classes.icon_hover} ${
                    variation != 1 || !props?.event?.showPlayer
                      ? classes.fontSizeIconVariation2
                      : classes.fontSizeIcon
                  }`}
                >
                  <h2
                    className={`${classes.a}`}
                    style={{
                      color: whiteColor ? "white" : "black",
                    }}
                  >
                    A
                  </h2>
                  <h4
                    className={`${classes.plus} ${classes.a}`}
                    style={{ color: whiteColor ? "white" : "black" }}
                  >
                    +
                  </h4>
                </div>
              </ActionIcon>

              <ActionIcon
                style={{ overflow: "hidden" }}
                aria-label={t("publicPage.font_size_decrease")}
                size="lg"
                lang="en"
                onClick={textFontSizeDecrease}
              >
                <div
                  className={`${!mobile && classes.icon_hover} ${
                    classes.fontSizeIcon
                  }`}
                >
                  <h3
                    className={classes.a}
                    style={{ color: whiteColor ? "white" : "black" }}
                  >
                    A
                  </h3>
                  <h3
                    className={`${classes.plus} ${classes.a}`}
                    style={{ color: whiteColor ? "white" : "black" }}
                  >
                    -
                  </h3>
                </div>
              </ActionIcon>
              {event?.showSignLanguage && (
                <ActionIcon
                  style={{ overflow: "hidden" }}
                  variant={showSignLanguage ? "filled" : undefined}
                  color={showSignLanguage ? "red" : "white"}
                  aria-label={t("show-sign-language-button")}
                  size="lg"
                  onClick={() => setShowSignLanugage(!showSignLanguage)}
                >
                  {!showSignLanguage ? (
                    <Image
                      style={{ width: "80%" }}
                      src={require("../../../assets/sign_language.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: "80%" }}
                      src={require("../../../assets/sign_language_white.png")}
                    />
                  )}
                </ActionIcon>
              )}
              {/* <ActionIcon
                style={{ overflow: "hidden" }}
                aria-label={t("font_-size-decrease")}
                size="lg"
                onClick={() => setShowSignLanugage(!showSignLanguage)}
              >
                {!showSignLanguage ? (
                  <Image
                    style={{ width: "80%" }}
                    src={require("../../../assets/sign_language.png")}
                  />
                ) : (
                  <Image
                    style={{ width: "80%" }}
                    src={require("../../../assets/sign_languge_close_black.png")}
                  />
                )}
              </ActionIcon> */}
              {showSignLanguage && event?.showSignLanguage && (
                <ActionIcon
                  style={{ overflow: "hidden" }}
                  aria-label={t("font_-size-decrease")}
                  lang="en"
                  size="lg"
                  onClick={signLanguagePosition}
                >
                  <div
                    className={`${!mobile && classes.icon_hover}`}
                    style={{
                      width: "70%",
                      border: `2px solid ${whiteColor ? "white" : "black"}`,
                      height: "70%",
                      borderRadius: 5,
                      position: "relative",
                    }}
                  >
                    {languagePosition == 1 && (
                      <div
                        className={`${!mobile && classes.icon_hover}`}
                        style={{
                          backgroundColor: whiteColor ? "white" : "black",
                          position: "absolute",
                          right: 0,
                          bottom: 0,
                          width: "60%",
                          height: "60%",
                        }}
                      ></div>
                    )}
                    {languagePosition == 2 && (
                      <div
                        className={`${!mobile && classes.icon_hover}`}
                        style={{
                          backgroundColor: whiteColor ? "white" : "black",
                          position: "absolute",
                          right: 0,
                          bottom: 2,
                          width: "60%",
                          height: "60%",
                        }}
                      ></div>
                    )}
                    {languagePosition == 3 && (
                      <div
                        style={{
                          backgroundColor: whiteColor ? "white" : "black",
                          position: "absolute",
                          left: 0,
                          bottom: 2,
                          width: "60%",
                          height: "60%",
                        }}
                      ></div>
                    )}
                  </div>
                </ActionIcon>
              )}
              {/* } */}
              {event.translations.length > 0 && (
                <ActionIcon
                  aria-label={t("publicPage.text_area_two_split")}
                  variant={divideIntoTwo ? "filled" : undefined}
                  color={divideIntoTwo ? "red" : "white"}
                  lang="en"
                  size="md"
                  onClick={getDivideIntoTwo}
                >
                  <TbLayoutColumns
                    style={{ width: "100%" }}
                    className={`${!mobile && classes.icon_hover}`}
                    size="xl"
                    color={whiteColor || divideIntoTwo ? "white" : "black"}
                  />
                </ActionIcon>
              )}
              {/* {event?.showDownload && event?.isRichText && showDownloadDocx && (
                <ActionIcon
                  onClick={() => setDownloadDocx(true)}
                  aria-label={t("download-word")}
                  size="md"
                >
                  <BsFiletypeDocx
                    style={{ width: "100%" }}
                    className={`${!mobile && classes.icon_hover}`}
                    size="xl"
                    color={whiteColor || divideIntoTwo ? "white" : "black"}
                  />
                </ActionIcon>
              )} */}
              {event?.showDownload && showTranslation && (
                <>
                  {currentTranslation == "preprocessed" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={"preprocessed"}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName="preprocessed"
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("download-pdf")}
                          styles={{
                            root: {
                              display: pdfButtonDisabled ? "none" : "block",
                            },
                          }}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}
                  {currentTranslation == "en" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={currentTranslation}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName={currentTranslation?.toUpperCase()}
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("publicPage.download_pdf")}
                          styles={{
                            root: {
                              display: pdfButtonDisabled ? "none" : "block",
                            },
                          }}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}
                  {currentTranslation == "de" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={currentTranslation}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName={currentTranslation?.toUpperCase()}
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("publicPage.download_pdf")}
                          styles={{
                            root: {
                              display: pdfButtonDisabled ? "none" : "block",
                            },
                          }}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}

                  {currentTranslation == "it" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={currentTranslation}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName={currentTranslation?.toUpperCase()}
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("publicPage.download_pdf")}
                          disabled={loading}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}
                  {currentTranslation == "uk" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={currentTranslation}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName={currentTranslation?.toUpperCase()}
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("publicPage.download_pdf")}
                          disabled={loading}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}
                  {currentTranslation == "rm" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={currentTranslation}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName={currentTranslation?.toUpperCase()}
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("publicPage.download_pdf")}
                          disabled={loading}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}
                  {currentTranslation == "fr" && (
                    <PDFDownloadLink
                      document={
                        <DownloadPdf
                          event={event}
                          language={currentTranslation}
                          setPdfButtonDisabled={setPdfButtonDisabled}
                        />
                      }
                      fileName={currentTranslation?.toUpperCase()}
                    >
                      {({ blob, url, loading, error }) => (
                        <ActionIcon
                          aria-label={t("publicPage.download_pdf")}
                          lang="en"
                          disabled={loading}
                          size="md"
                        >
                          <BsFiletypePdf
                            style={{ width: "100%" }}
                            className={`${!mobile && classes.icon_hover}`}
                            size="xl"
                            color={whiteColor ? "white" : "black"}
                          />
                        </ActionIcon>
                      )}
                    </PDFDownloadLink>
                  )}
                </>
              )}
            </>
          )}

          {!textFullSize &&
            variation != 2 &&
            variation != 3 &&
            mobile && ( // mobile settings area
              <>
                <ActionIcon
                  size="lg"
                  aria-label={t("settings")}
                  variant={settingsOpen ? "filled" : undefined}
                  lang="en"
                  color={settingsOpen ? "red" : "white"}
                  onClick={() => {
                    setSettingsOpen(!settingsOpen);
                    setSettingsOpenFullScreen(false);
                  }}
                >
                  <Settings
                    style={{ width: "100%" }}
                    size="xl"
                    color={settingsOpen ? "white" : "black"}
                  />
                </ActionIcon>
                {!event.showPlayer ? (
                  <ActionIcon
                    aria-label={t("publicPage.change_size")}
                    lang="en"
                    onClick={changeScaleNoVideo}
                    size="lg"
                  >
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: whiteColor ? "white" : "black",
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        {variationNoVideo}
                      </h3>
                      <ArrowsDiagonal
                        style={{ width: "100%" }}
                        size="md"
                        color={textFullSize ? "white" : "black"}
                      />
                    </div>
                  </ActionIcon>
                ) : (
                  <ActionIcon
                    aria-label={t("publicPage.change_size")}
                    size="lg"
                    lang="en"
                    onClick={changeScale}
                  >
                    <ArrowsDiagonal
                      style={{ width: "100%" }}
                      size="xl"
                      color={whiteColor ? "white" : "black"}
                    />
                    {variation}
                  </ActionIcon>
                )}
              </>
            )}
        </>
      </Group>
    );
  };

  return (
    <>
      {/* {Object.keys(urlParams).length == 0 &&
        props?.event?.showPlayer &&
        props?.event?.sources[0].src != "" && (
          <MemberNextTimeModal
            mobile={mobile}
            setScale={setScale}
            setVariation={setVariation}
          />

        )} */}

      <Group
        align="top"
        className={`${classes.main_container} ${
          textFullSize && classes.text_full_size_main_container
        }`}
      >
        {(textFullSize || variation == 2 || variation == 3) &&
          (Object.keys(urlParams).length < 1 ||
            (Object.keys(urlParams).length == 1 && "delay" in urlParams)) && ( // full screen settings area
            <InterpreterFullScreenSettings
              mobile={mobile}
              changeScale={changeScale}
              textFullSize={textFullSize}
              variationNoVideo={variationNoVideo}
              changeScaleNoVideo={changeScaleNoVideo}
              variation={variation}
              whiteColor={whiteColor}
              settingsOpenFullScreen={settingsOpenFullScreen}
              setSettingsOpen={setSettingsOpen}
              setSettingsOpenFullScreen={setSettingsOpenFullScreen}
              settingsItems={settingsItems}
              settingsMenuClose={settingsMenuClose}
            />
          )}
        <div ref={ref} className={`${classes.editor_main_area}`}>
          <div className={`${classes.editor_container} `}>
            <div className={`${classes.editor_text_area} `}>
              <div
                className={`${classes.left_area} ${
                  !showVideo && classes.editor_area_hidden_video
                }`}
              >
                <>
                  <div
                    // onTouchStart={() =>
                    //   setClickVideoMobile(!clickVideoMobile)
                    // }
                    className={`${classes.video_area}
                    ${variation == 1 && classes.video_area}
                    ${variation == 2 && classes.video_area_three_variation}
                    ${variation == 3 && classes.video_area_four_variation}
                    `}
                  >
                    {!textFullSize && showVideo && (
                      <div
                        style={{
                          position: "relative",
                          height:
                            variation == 2
                              ? "100%"
                              : variation == 3
                              ? "70%"
                              : "60%",
                        }}
                      >
                        {!initLoaded ? (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "black",
                              height: "100%",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <Loader color="red" variant="dots" />
                          </div>
                        ) : currentRaiseHandState?.showPreview ? (
                          props?.event.isPreviewCustomLogo &&
                          props?.event?.livePreviewLogos?.length == 1 ? (
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: "black",
                                height: "100%",
                                display: "flex",
                                placeItems: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Image
                                fit="cover"
                                styles={{
                                  root: { width: "100%", height: "100%" },
                                  figure: { width: "100%", height: "100%" },
                                  imageWrapper: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  image: { height: "100% !important" },
                                }}
                                src={props?.event?.livePreviewLogos[0]?.img}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                backgroundColor: "black",
                                height: "100%",
                                display: "grid",
                                placeItems: "center",
                              }}
                            >
                              <Image
                                src={require("../../../assets/srg_ssr_logo.png")}
                                height={60}
                              />
                            </div>
                          )
                        ) : (
                          <>
                            <Player
                              pageName="publicEvent"
                              alwaysController={variation == 2}
                              onPlayerInit={setPlayer}
                              playerOptions={playerOptions}
                              onPlayerDispose={setPlayer}
                              showPlayer={props?.event?.showPlayer}
                              sources={props?.event?.sources}
                              onAudioTracksInit={handleAudioTracks}
                              event={event}
                              delay={urlParams?.delay ? urlParams.delay :  20000}
                              subtitle={true}
                            />
                            {signLanguageState?.liveStream &&
                              showSignLanguage &&
                              event?.showSignLanguage && (
                                // signLanguageState != undefined &&
                                <div
                                  style={{
                                    position: "absolute",
                                    right:
                                      languagePosition == 1 || 2
                                        ? 0
                                        : undefined,
                                    left: languagePosition == 3 ? 0 : undefined,
                                    top: variation == 2 ? 0 : undefined,
                                    bottom:
                                      variation == 2
                                        ? undefined
                                        : languagePosition == 1
                                        ? 0
                                        : 35,
                                  }}
                                >
                                  <SignLanguagePublic
                                    event={event}
                                    languagePosition={languagePosition}
                                    showSignLanguage={showSignLanguage}
                                  />
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    )}
                    {(Object.keys(urlParams).length < 1 ||
                      (Object.keys(urlParams).length == 1 &&
                        "delay" in urlParams)) &&
                      mobile &&
                      !textFullSize &&
                      variation == 1 && (
                        <div className={classes.settings_area}>
                          {settingsItems()}
                        </div>
                      )}
                    <div
                      // style={{ width: textAreaWidth }}
                      className={`${classes.editor_text}
                ${variation == 1 && classes.editor_text_two_variation}
                ${
                  variationNoVideo == 1 &&
                  !textFullSize &&
                  classes.editor_text_no_video_variation_one
                }
                ${variation == 2 && classes.editor_text_three_variation}
                ${
                  variationNoVideo == 2 &&
                  classes.editor_text_no_video_variation_two
                }
                ${variation == 3 && classes.editor_text_four_variation}
                ${textFullSize && classes.full_size_editor}

             `}
                    >
                      <div
                        className={`${classes.editor_text_main_area}

                  ${
                    variation == 3 &&
                    classes.editor_text_main_area_four_variation
                  }
                  ${
                    variation == 2 &&
                    classes.editor_text_main_area_full_video_variation
                  }
                  ${
                    variation == 1 &&
                    classes.editor_text_main_area_two_variation
                  }

                  ${textFullSize && classes.full_size_editor} `}
                      >
                        <div
                          className={`${classes.two_text_editor} ${
                            textFullSize && classes.two_text_editor_full_size
                          }`}
                        >
                          {event?.isRichText && (
                            <RichTextEditor
                              id={event.uid}
                              downloadWorld={downloadWorld}
                              setDownloadDocx={setDownloadDocx}
                              setShowDownloadDocx={setShowDownloadDocx}
                              readOnly={true}
                              collection={"richtexts"}
                              fontSize={textAreaFontSize + "px"}
                              autoScroll={true}
                            />
                          )}
                          {!showTranslation &&
                            !currentTranslation &&
                            !event.isRichText && (
                              <TextAreaEditor
                                readonly={true}
                                event={event}
                                mobile={mobile}
                                showVideo={showVideo}
                                variation={variation}
                                divideIntoTwo={divideIntoTwo}
                                textCenter={
                                  !textFullSize &&
                                  variation != 1 &&
                                  !divideIntoTwo &&
                                  true
                                }
                                autoScroll={true}
                                scrollHidden={true}
                                fontSize={
                                  variation == 2 ? textFontV2 : textAreaFontSize
                                }
                                color={color()}
                              />
                            )}
                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "en" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "en" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )}

                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "de" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "de" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )}
                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "pre" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={"preprocessed"}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "pre" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={"pre"}
                                  color={color()}
                                />
                              )}

                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "rm" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "rm" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )}
                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "uk" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "uk" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )}
                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "fr" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "fr" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )}
                          {urlParams.delay == undefined ||
                          urlParams.delay == "0"
                            ? showTranslation &&
                              currentTranslation === "it" && (
                                <TranslationArea
                                  variation={variation}
                                  event={event}
                                  autoScroll={true}
                                  textCenter={
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    !textFullSize &&
                                    true
                                  }
                                  mobile={mobile}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )
                            : showTranslation &&
                              currentTranslation === "it" && (
                                <TranslationReplayArea
                                  variation={variation}
                                  event={event}
                                  mobile={mobile}
                                  delay={urlParams.delay}
                                  textCenter={
                                    !textFullSize &&
                                    variation != 1 &&
                                    !divideIntoTwo &&
                                    true
                                  }
                                  autoScroll={true}
                                  fontSize={
                                    variation == 2
                                      ? textFontV2
                                      : textAreaFontSize
                                  }
                                  language={currentTranslation}
                                  color={color()}
                                />
                              )}

                          {divideIntoTwo &&
                            props.event.translations.length > 0 && (
                              <>
                                {!showSecondTranslation &&
                                  !currentSecondTranslation && (
                                    <TextAreaEditor
                                      readonly={true}
                                      variation={variation}
                                      event={event}
                                      mobile={mobile}
                                      divideIntoTwo={divideIntoTwo}
                                      textCenter={
                                        !textFullSize &&
                                        variation != 1 &&
                                        !divideIntoTwo &&
                                        true
                                      }
                                      autoScroll={true}
                                      scrollHidden={true}
                                      fontSize={
                                        variation == 2
                                          ? textFontV2
                                          : textAreaFontSize
                                      }
                                      color={color()}
                                    />
                                  )}
                                {/* {showSecondTranslation &&
                                  currentSecondTranslation === "en" && (
                                    <TranslationArea
                                      variation={variation}
                                      event={event}
                                      textCenter={
                                        !textFullSize &&
                                        variation != 1 &&
                                        !divideIntoTwo &&
                                        true
                                      }
                                      autoScroll={true}
                                      mobile={mobile}
                                      fontSize={
                                        variation == 2
                                          ? textFontV2
                                          : textAreaFontSize
                                      }
                                      language={currentSecondTranslation}
                                      color={color()}
                                    />
                                  )} */}

                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation ===
                                      "preprocessed" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation ===
                                      "preprocessed" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation === "en" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation === "en" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation === "rm" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation === "rm" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation === "uk" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation === "uk" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation === "fr" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation === "fr" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation === "it" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation === "it" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                                {urlParams.delay == undefined ||
                                urlParams.delay == "0"
                                  ? showSecondTranslation &&
                                    currentSecondTranslation === "de" && (
                                      <TranslationArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )
                                  : showSecondTranslation &&
                                    currentSecondTranslation === "de" && (
                                      <TranslationReplayArea
                                        variation={variation}
                                        event={event}
                                        mobile={mobile}
                                        delay={urlParams.delay}
                                        textCenter={
                                          !textFullSize &&
                                          variation != 1 &&
                                          !divideIntoTwo &&
                                          true
                                        }
                                        autoScroll={true}
                                        fontSize={
                                          variation == 2
                                            ? textFontV2
                                            : textAreaFontSize
                                        }
                                        language={currentSecondTranslation}
                                        color={color()}
                                      />
                                    )}
                              </>
                            )}
                        </div>
                      </div>

                      {!mobile &&
                        !textFullSize &&
                        variation != 2 &&
                        variation != 3 &&
                        !showVideo &&
                        (Object.keys(urlParams).length < 1 ||
                          (Object.keys(urlParams).length == 1 &&
                            "delay" in urlParams)) &&
                        settingsItems()}
                    </div>
                  </div>
                </>
              </div>
              {!mobile &&
                !textFullSize &&
                variation != 2 &&
                variation != 3 &&
                showVideo &&
                (Object.keys(urlParams).length < 1 ||
                  (Object.keys(urlParams).length == 1 &&
                    "delay" in urlParams)) &&
                settingsItems()}
            </div>
          </div>
        </div>
      </Group>
    </>
  );
};
const useStyles = createStyles((theme) => ({
  main_container: {
    height: "100%",
    position: "relative",
  },
  text_full_size_main_container: {
    position: "inherit",
  },
  blank: {
    width: 65,
    "@media (max-width: 3000px)": {
      width: 64,
    },
    "@media (max-width: 1880px)": {
      width: 65,
    },
    "@media (max-width: 1700px)": {
      width: 66,
    },
    "@media (max-width: 1650px)": {
      width: 67.5,
    },
    "@media (max-width: 1250px)": {
      width: 66,
    },
    "@media (max-width: 1100px)": {
      width: 67,
    },
    "@media (max-width: 960px)": {
      width: 67.5,
    },
  },
  zero_blank: {
    width: 0,
  },
  full_size_editor: {
    width: "100vw !important",
    height: "100vh !important",
    top: 0,
    left: 0,
    position: "absolute",
    zIndex: 9,
  },
  two_text_editor_full_size: {
    width: "100vw",
    overflow: "hidden",
  },
  two_text_editor: {
    display: "flex",
    height: "100%",
  },
  editor_area_hidden_video: {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  editor_main_area: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    paddingTop: 0,
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "100% !important",
      paddingTop: 0,
    },
  },

  editor_container: {
    width: "49%",
    height: "100%",
    "@media (max-width: 1450px)": {
      width: "80% ",
    },
    "@media (max-width: 550px)": {
      width: "100% ",
    },
  },
  big_video_area: {
    height: "60% !important",
  },
  small_video_area: {
    height: "30% !important",
  },

  editor_text: {
    height: "48%",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  editor_text_rich_text: {
    height: "100% !important",
  },
  small_editor_text: {
    height: "32% !important",
  },

  editor_text_area: {
    display: "flex",
    width: "100%",
    height: "100%",
  },

  editor_text_main_area: {
    height: "70%",
    width: "100%",
  },
  editor_text_no_video_variation_one: {
    marginTop: "25vh",
  },
  editor_text_no_video_variation_two: {
    height: "100% !important",
  },
  editor_text_main_area_full_video_variation: {
    height: "100%",
  },
  editor_text_main_area_rich_text: {
    height: "100% !important",
  },
  editor_text_main_area_four_variation: {
    height: "100%",
  },
  editor_text_main_area_two_variation: {
    height: "100%",
  },
  video_area: {
    height: "100%",
    marginBottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      marginBottom: 0,
    },
  },
  left_area: {
    width: "100%",
  },

  line_area: {
    display: "flex",
    width: "100%",
  },
  line: {
    backgroundColor: theme.colors.gray[2],
    width: "100%",
    height: 2,
  },
  editor_button_area: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    marginLeft: 10,
    height: "100%",
  },
  fontSizeIconVariation2: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: 34,
    width: 34,
  },
  fontSizeIcon: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    height: 28,
    width: 28,
  },
  variation: {
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
    position: "absolute",
    left: 0,
    top: 0,
  },
  plus: {
    position: "absolute",
    top: "-2px",
    right: 0,
  },
  a: {
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
  },
  icon_hover: {
    ":hover": {
      filter: "grayscale(100%) brightness(0%) hue-rotate(360deg)",
    },
  },
  settings_button_area: {
    backgroundColor: "rgba(255,255,255)",
    borderRadius: "0px 0px 10px 10px",
    padding: "0px 4px",
    boxShadow: "5px 0px 21px -6px rgba(0,0,0,0.61)",
    "@media (max-width: 768px)": {
      borderRadius: "10px 0px 0px 10px",
    },
  },
  settings_area: {
    paddingTop: 10,
    paddingBottom: 10,
    "@media (max-width: 768px)": {
      display: "flex",
      paddingTop: 5,
      paddingBottom: 5,
      justifyContent: "flex-end",
    },
  },

  language_box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  language_box_column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  editor_text_two_variation: {
    height: "40%",
    "@media (max-width: 550px)": {
      height: "calc(40% - 63px) ",
    },
  },
  skip_button: {
    ul: {
      marginBlockStart: "0em",
      marginBlockEnd: "0em",
    },
  },
  video_area_three_variation: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 9,
    height: "100% !important",
  },
  editor_text_three_variation: {
    position: "absolute",
    zIndex: 9,
    bottom: "53px",
    overflow: "hidden",
    width: "100%",
    left: 0,
    right: 0,
    height: "125px",
  },

  video_area_four_variation: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 9,
  },
  editor_text_four_variation: {
    position: "absolute",
    zIndex: 9,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "30%",
  },
}));
export default InterpreterArea;
